import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'yellow-time-web-info-dialog',
  templateUrl: './info-dialog.component.html',
  styleUrls: ['./info-dialog.component.scss']
})
export class InfoDialogComponent implements OnInit {
  message: any;
  title:any;
  actionName: any;
  constructor(private dialogRef: MatDialogRef<InfoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data: any) {
      this.message = data.message
      this.title = data.title
      this.actionName = data.actionName ? 'INFODIALOG.TASKDRAG.NO' : 'COMMON.NO';
  }

  ngOnInit(): void {}

  onSave(){
    this.dialogRef.close(true);
  }

  onCancel(){
    this.dialogRef.close(false);
  }
}

<h2 mat-dialog-title>{{'AUTH.SETTINGS' | translate}}</h2>
<mat-dialog-content class="mat-typography">
    <div fxFlex="100" fxLayout="column">
        <div fxLayout="row wrap">
            <div fxLayout="column" class="nav-height" fxFlex="35">
                    <mat-tab-group animationDuration="0ms" (selectedTabChange)="onTabChange($event)">
                        <mat-tab label="{{'COMMON.LANGUAGE' | translate}}"></mat-tab>
                        <mat-tab label="{{'COMMON.SUPPORT' | translate}}"></mat-tab>
                    </mat-tab-group>
            </div>
            <div fxLayout="column" fxFlex="65" *ngIf="selectedTabIndex === 0">
                <yellow-time-web-language fxFlex (languageEvent)="onSetLanguage($event)"></yellow-time-web-language>
            </div>
            <div fxLayout="column" fxFlex="65" class="p-3" *ngIf="selectedTabIndex === 1">
                <mat-form-field appearance="outline" class="example-full-width">
                    <mat-label>{{'COMMON.SUPPORT' | translate}}</mat-label>
                    <textarea type="text" matInput [formControl]="messageControl" id="message" name="message" placeholder="">
                    </textarea>
                </mat-form-field>
            </div>

        </div>
        <div fxLayout="column" fxFlex="100">
            <div fxLayout="row wrap">
            <div fxLayout="column" fxFlex="30" class="mx-2">
                <div fxLayout="row" class="sm">App version: 2.0.7</div>
                <div fxLayout="row" class="sm">Released on: 12.10.2024 08:09</div>
            </div>
            <div fxLayout="column" fxFlex="60" class="mx-2">
                <div fxLayout="row wrap">
                    <div fxLayout="column" class="mx-2">
                        <button mat-raised-button color="primary" (click)="onSave()" class="action-btn">{{'COMMON.SAVE' |
                            translate}}</button>
                    </div>
                    <div fxLayout="column" class="mx-2">
                        <button mat-raised-button color="secondary" data-dismiss="modal" #closeAddExpenseModal
                            (click)="onCancel()">{{'COMMON.CANCEL' | translate}}</button>
                    </div>
                </div>
            </div>
        </div>
        </div>
    </div>
</mat-dialog-content>

import { Inject, Injectable } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { SECRET_KEY } from 'apps/yellow-time/src/environments/environment.factory';
import * as CryptoJS from 'crypto-js';
import * as moment from 'moment';
import { ISet, ISetValue, ITime } from '../../../interface/shared/shared.interface';
import { ACTION, PAGEVIEW } from '../../enum/enum';

@Injectable({
    providedIn: 'root'
})
export class UtilityService {
    constructor(@Inject(SECRET_KEY) private sKey: string, private _xService: TranslateService) { }

    setID(key: any) {
        function random() {
            return Math.floor((1 + Math.random()) * 0x10000)
                .toString(16)
                .substring(1);
        }
        return random() + '-' + random() + '-' + random() + '-' +
            random() + '-' + random() + random() + random() + '-' + key;
    }

    getID(key: any) {
        if (key) {
            const out = key?.split('-').pop();
            const [digits, word] = out?.match(/\D+|\d+/g);
            return digits;
        } else{
            return 0;
        }
    }

    setKey(key: any) {
     return this.encrypt(key,this.sKey)
    }

    getKeys(key: any) {
        let set = <ISet>{};
        const [digits,numbers,word] = key != 0 ? this.decrypt(key,this.sKey)?.split('|') : [0,0,''];
        set.id = Number(digits);
        set.no = Number(numbers);
        set.action = word;
        set.view =  ACTION.CREATE == word ? PAGEVIEW.CREATE : ACTION.EDIT == word ? PAGEVIEW.EDIT : PAGEVIEW.READ
        return set;
    } 

    
    getKey(key: any) {
        let set = <ISet>{};
        const [digits, word] = key != 0 ? this.decrypt(key,this.sKey)?.match(/\D+|\d+/g) : [0,''];
        set.id = Number(digits);
        set.action = word;
        set.view =  ACTION.CREATE == word ? PAGEVIEW.CREATE : ACTION.EDIT == word ? PAGEVIEW.EDIT : PAGEVIEW.READ
        return set;
    }
   
    getKeyvalue(key: any) {
        let set = <ISetValue>{};
        const [digits,start,end] = key != 0 ? this.decrypt(key,this.sKey)?.split('|') : [0,0,''];
        set.id = Number(digits);
        set.start = new Date(start);
        set.end =  new Date(end);
        return set;
    }

    findByProperty(o:any, predicate:any):any {
        if (predicate(o)) return o
        for(let n of Object.values(o).filter(Boolean).filter(v => typeof v === 'object')) {
            let found = this.findByProperty(n, predicate)
            if (found) return found
        }
    }

    findByValue(o:any, val:any):any  {
        if (o === val) return o;
        if (Number.isNaN(o) || o === Infinity || !o || typeof o !== 'object') return;
        if (Object.values(o).includes(val)) return o;
        for (let n of Object.values(o)) {
            const found = this.findByValue(n, val)
            if (found) return n
        }
    }

    getTime():any{
        let _timeInterval = 30; //Time Interval
        let _timeArray = []; // time array
        let _startTime = 0; // start time
       // let ap = ['AM', 'PM']; // AM-PM
        for (var i=1;_startTime<24*60; i++) {
          var hh = Math.floor(_startTime/60); // getting hours of day in 0-24 format
          var mm = (_startTime%60); // getting minutes of the hour in 0-55 format
          _timeArray[i] = ("0" + (hh % 24)).slice(-2) + ':' + ("0" + mm).slice(-2); // ap[Math.floor(hh/12)]; // pushing data in array in [00:00 - 12:00 AM/PM and also change the value from 24 to 12
          _startTime = _startTime + _timeInterval;
        }
       return _timeArray;
      }
    
    
    isNotEmptyObject(x:any): boolean {
        return !(x && (Object.keys(x).length === 0));
    }

    isNotNull(x: any): boolean {
        return !(x === undefined || x === null || x === "")
    }

    stringToDate(_date: any, _format: any, _delimiter: any) {
        let formatLowerCase = _format.toLowerCase();
        let formatItems = formatLowerCase.split(_delimiter);
        let dateItems = _date.split(_delimiter);
        let monthIndex = formatItems.indexOf("mm");
        let dayIndex = formatItems.indexOf("dd");
        let yearIndex = formatItems.indexOf("yyyy");
        let year = parseInt(dateItems[yearIndex]);
        // adjust for 2 digit year
        if (year < 100) { year += 2000; }
        let month = parseInt(dateItems[monthIndex]);
        month -= 1;
        let formatedDate = new Date(year, month, dateItems[dayIndex]);
        return formatedDate;
    }

    calculateAge(birthdate: any): number {
        return moment().diff(birthdate, 'years');
     }

   formatDate(date:any){
    if(!this.isNotNull(date))
        return null;
     else
        return moment(date,'YYYY-MM-DD[T]HH:mm:ss.SSS[Z]').format('L');
   }

   formatEndDate(date){
    if(!this.isNotNull(date))
        return null;
     else{
        let timezoneOffset = moment(date).utcOffset();
        return moment(date,).utc().add(timezoneOffset, 'minutes').endOf('day').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')
     }
  }

    formatStartDate(date){
        if(!this.isNotNull(date))
            return null;
         else{
            let timezoneOffset = moment(date).utcOffset();
            return moment(date).utc().add(timezoneOffset, 'minutes').startOf('day').format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]')
         }

    }

   
   findBetweenDays(from:any,to:any){
    if(!this.isNotNull(from) && !this.isNotNull(to))
        return null;
     else  
        var startDate = moment(from, "DD.MM.YYYY");
        var endDate = moment(to, "DD.MM.YYYY");
        return endDate.diff(startDate, 'days');
   }

   formatDateUTC(date:any){
    if(!this.isNotNull(date))
        return null;
     else
      return moment(new Date(date)).format('YYYY-MM-DD[T]HH:mm:ss.SSS[Z]');
   }

   formatDateTime(date:any){
    let set = <ITime>{};
    if(!this.isNotNull(date))
        return null;
     else{
        let [hour, minute] = [new Date(date).getHours(),new Date(date).getMinutes()];
        set.startDT = moment(new Date(date)).date;
        set.endDT = moment(new Date(date)).day;
     }

      return set;
   }

   formatDT(date:any){
    let set = <ITime>{};
    if(!this.isNotNull(date))
        return '';
     else{
       return moment(new Date(date)).format('DD.MM.yyyy, HH:mm');
     }
   }

   formatStandardDate(date:any){
    let set = <ITime>{};
    if(!this.isNotNull(date))
        return '';
     else{
       return moment(new Date(date)).format('MM.DD.yyyy');
     }
   }

   formatTime(time:any){
        let value= time != '' ? time.replace(':','') : '';  
        if(!this.isNotNull(time)){
            return '';
        } else {
            let s = value.length;
            switch(s){
            case 1:
                value = "0" + value + "00";
                break;
            case 2:
                value = value + "00";
                break;
            case 3:
                value = "0" + value;
                break;
            }
            if(value.length == 4){
            value = value.match(/.{1,2}/g).join(":")
            }
        }
        return value;   
   }

    getDatesDiff =(startDate, endDate) => {
        const dates = [];
        // Strip hours minutes seconds etc.
        let currentDate = new Date(
            startDate.getFullYear(),
            startDate.getMonth(),
            startDate.getDate()
        );
    
        while (currentDate <= endDate) {
            dates.push(currentDate);
    
            currentDate = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth(),
                currentDate.getDate() + 1, // Will increase month if over range
            );
        }
    
        return dates;
    };

   upsert(array:any, item:any) { 
    const index = array.findIndex((e) => e.id === item.id);
    if (index === -1) {
        array.push(item);
    } else {
        array[index] = item;
    }
    return array;
   }


    encrypt(text: string, skey: string) {
        if (this.isNotNull(text))
            return CryptoJS.AES.encrypt(text, skey.trim()).toString();
        else
            return null;
    }

    decrypt(text: string, skey: string) {
        if (this.isNotNull(text))
            return CryptoJS.AES.decrypt(text, skey.trim()).toString(CryptoJS.enc.Utf8);
        else
            return null;
    }

    getMin(cmp, arr, attr) {
        if(arr.length > 0){
            var val = arr[0][attr];
            for(var i=1;i<arr.length;i++) {
                val = cmp(val, arr[i][attr])
            }
            return val < 0 ? (val + (-1)) : (val == 0) ? (val + (-1)) : 0 
        } else {
            return -1;
        }
 
    }

    getErrors(dataSet:any){
        let eMessage = [];
        dataSet?.error?.data.map(item=>{
            // if(dataSet?.error?.key) { // Enable this code when they give the key as false
            //     this._xService.get(item?.message).subscribe((x:any)=>{
            //        let out = {
            //         'field': item.field,
            //         'message':x
            //        }
            //       eMessage.push(out);
            //   })
            // } else{
                item['banner'] = item?.field == null ? true : false;
                eMessage.push(item);
           // }
        });
        return eMessage;
    }

    groupByKey(data:any, key:any) {
        let out = data.reduce(function (rv, x) {
            (rv[x[key]] = rv[x[key]] || []).push(x);
            return rv
          }, {});
          if(key == 'groupName'){
            Object.keys(out).map(x => {  out[x].sort((a,b) => a.groupName.localeCompare(b.groupName) || a.name.localeCompare(b.name)) });
          }
        return out
      };

    getInvalids(input: FormGroup | FormArray): string[] {
        let controls: any[] = [];
        Object.keys(input.controls).forEach((controlName) => {
        const control = input.get(controlName)!;
        if (control.invalid && control instanceof FormControl) {
            let out = {
                'field': controlName,
                'message':''
            }  
            this._xService.get('VALIDATION.GENERIC.MESSAGE').subscribe((x:any)=>{
                out.message = x; 
            })
            controls.push(out);
        } else if (
          control.invalid &&
          (control instanceof FormGroup || control instanceof FormArray)
        ) {
            controls.push(...this.getInvalids(control));
        }
        });
        return [...new Set(controls)];
      }

      splitMessages(message: any) {
        const keys = Object.keys(message);
        keys.map(item => {
            let out = '';
            if (message[item] != '') {
                let messageKey = message[item].split(',');
                if (messageKey.length > 1) {
                    messageKey.map(x => {
                        if(x != ''){
                            this._xService.get(x).subscribe((res: any) => {
                               if(out == ''){
                                 out = res;
                               }
                            });
                        }
                    });
                       message[item] = out;
                } else {
                    this._xService.get(message[item]).subscribe((res: any) => {
                        message[item] = res;
                    });
                }
            }
        });
        return message;
    }
      
    generateRepeatText(frequency, fromDate, toDate, days, every, repeaton) {
        const value = frequency;
        const formattedFromDate = this.formatDate(fromDate);
        const formattedToDate = this.formatDate(toDate);
        const daysOfWeek = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
        const daysText = days.map(day => daysOfWeek[day]).join(', ');
        const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
        const monthsText = days.map(month => months[month]).join(', ');
        let repeatText = '';
        repeatText += (value == 2 && days && days.length > 0 && every) ? `Every ${daysText} ${every} week` :  
        (value == 3 && days && days.length > 0 && every && repeaton) ? `Every ${daysText} ${every} week` : 
        (value == 4 && days && days.length > 0 && every && repeaton) ? `Every ${monthsText}` :  '';

        repeatText += (formattedFromDate && !formattedToDate) ? ` Effective from ${formattedFromDate}` :  (formattedFromDate && formattedToDate) ? ` Effective from ${formattedFromDate} until ${formattedToDate}` :  '';

        return repeatText;
    } 

}
export function ConfirmedValidator(controlName: string, matchingControlName: string){
    return (formGroup: FormGroup) => {
        const control = formGroup.controls[controlName];
        const matchingControl = formGroup.controls[matchingControlName];
        if (matchingControl.errors && !matchingControl.errors.confirmedValidator) {
            return;
        }
        if (control.value !== matchingControl.value) {
            matchingControl.setErrors({ confirmedValidator: true });
        } else {
            matchingControl.setErrors(null);
        }
    }
  }